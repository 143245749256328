
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { AccountActions } from "@/store/vm/account/enums";
import { StepResult } from "@/store/captain/CaptainModule";

export default defineComponent({
  name: "sign-up",
  components: {},
  setup() {
    const store = useStore();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);

    const defaultData = {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      role: "guest",
      gender: "male",
      birthday: "2021-01-01",
    };

    const formData = ref({ ...defaultData });

    const labelGenders = [
      {
        value: "male",
        label: "Nam",
      },
      {
        value: "female",
        label: "Nữ",
      },
    ];

    const labelRoles = [
      {
        value: "teacher",
        label: "Giảng viên",
      },
      {
        value: "student",
        label: "Học viên",
      },
      {
        value: "employee",
        label: "Nhân viên",
      },
      {
        value: "saler",
        label: "Sale",
      },
      {
        value: "marketer",
        label: "Marketer",
      },
      {
        value: "guest",
        label: "Khách",
      },
    ];

    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    const validateVietnamPhone = (str) => {
      const regex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/gm;
      let m;
      let isMatching = false;
      while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          isMatching = true;
        });
      }
      return isMatching;
    };

    var validateUsername = (rule, value, callback) => {
      if (validateEmail(value) || validateVietnamPhone(value)) {
        callback();
      } else {
        callback(
          new Error("Username cần có định dạng email hoặc số điện thoại!")
        );
      }
    };

    const rules = ref({
      firstName: [
        { required: true, message: "Bạn cần điền tên.", trigger: "blur" },
        {
          min: 2,
          max: 30,
          message: "Độ dài từ 2 đến 30 ký tự",
          trigger: "blur",
        },
      ],
      username: [
        { required: true, message: "Bạn cần điền tên.", trigger: "blur" },
        {
          min: 2,
          max: 30,
          message: "Độ dài từ 2 đến 30 ký tự",
          trigger: "blur",
        },
        { validator: validateUsername, trigger: "blur" },
      ],
      password: [
        {
          required: true,
          message: "Yêu cầu nhập mật khẩu.",
          trigger: "blur",
        },
        {
          min: 6,
          max: 30,
          message: "Mật khẩu dài từ 6 đến 30 ký tự",
          trigger: "blur",
        },
      ],
    });

    const actionCreate = (par) => {
      return store.dispatch(AccountActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }
      console.log(formRef.value);

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          const stepResult: StepResult = await actionCreate(formData.value);
          console.log(stepResult);
          if (stepResult.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            reset();
          } else {
            const message = (stepResult.data as any).message;
            Swal.fire({
              position: "top-end",
              text: message,
              icon: "error",
              buttonsStyling: false,
              timer: 3000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    const reset = () => {
      formData.value = defaultData;
    };

    return {
      defaultData,
      labelGenders,
      labelRoles,
      formData,
      rules,
      submit,
      formRef,
      reset,
      actionCreate,
    };
  },
});
